import React, { useEffect, useRef } from 'react'

import { initWithCanvas } from '../FlameScripts/pgEmbed'

type Props = {
  visible: boolean
}

export const MemoizedFlame = React.memo(function Flame(props: Props) {
  const { visible } = props
  const canvasEl = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const shaderToy = initWithCanvas(canvasEl.current)

    return () => {
      shaderToy.Kill()
    }
  })

  return (
    <canvas
      ref={canvasEl}
      className={`flame ${visible ? 'visible' : 'invisible'}`}
      draggable={false}
      style={{
        zIndex: 1010
      }}
    />
  )
})

// iVolume shader uniform will be set in shader render/paint call
// from window.Hanukkah.iVolume which is set in the audio stream by volume-meter.js
// for reference, calling initWithCanvas returns to you `shaderToy` which you
// could then use to set the shader constant hence:
// shaderToy.mEffect.mPasses[0].mRenderer.SetShaderConstant1F(
//   'iVolume',
//   meter.volume
// )
