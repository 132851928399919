import React, { MouseEvent } from 'react'
import dreidel from '../media/dreidel-anim-small.gif'

type Props = {
  onStart: (event: MouseEvent) => void
}

function Landing(props: Props) {
  const { onStart } = props

  return (
    <div className="container-fluid d-flex flex-column h-100">
      <div className="row align-items-center justify-content-center flex-fill">
        <div className="col col-lg-4 col-xxl-2 text-center">
          <img
            className="dreidel img-fluid mx-auto mb-5"
            draggable={false}
            src={dreidel}
            alt="Dreidel"
          />
          <h1 className="h3 text-secondary">
            At least for 8 straight nights you have something to do. Kind of.
          </h1>
        </div>
      </div>

      <div className="row align-items-center justify-content-center flex-fill">
        <button
          className="btn btn-link btn-lg text-uppercase"
          onClick={onStart}
        >
          Start
        </button>
      </div>
    </div>
  )
}

export default Landing
