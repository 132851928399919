import { createAudioMeter } from './volume-meter'

var audioContext = null;
var meter = null;

export function initVolumeMeter() {
  // monkeypatch Web Audio
  window.AudioContext = window.AudioContext || window.webkitAudioContext;

  // grab an audio context
  audioContext = new AudioContext();

  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    var constraints = {
      audio: {
        echoCancellation: false,
        noiseSuppression: false,
        autoGainControl: false,
      }
    };
    
    navigator.mediaDevices.getUserMedia(constraints)
      .then(gotStream)
      .catch(didntGetStream);
  } else {
    console.error("navigator.mediaDevices not supported")
  }
}

function didntGetStream(err) {
  console.error('Stream generation failed.', err);
}

var mediaStreamSource = null;

function gotStream(stream) {
  // Create an AudioNode from the stream.
  mediaStreamSource = audioContext.createMediaStreamSource(stream);

  // Create a new volume meter and connect it.
  meter = createAudioMeter(audioContext);
  mediaStreamSource.connect(meter);

  // kick off the visual updating
  // setInterval(function() {
  //   console.log('meter', meter.volume);
  // }, 100)
}
