import React, { MouseEvent } from 'react'
import dreidel from '../media/dreidel-anim.gif'

type Props = {
  onClick: (event: MouseEvent) => void
}

function Dreidel(props: Props) {
  const { onClick } = props

  return (
    <img
      className="dreidel clickable img-fluid mx-auto mb-5"
      draggable={false}
      onClick={onClick}
      src={dreidel}
      alt="Dreidel"
    />
  )
}

export default Dreidel
