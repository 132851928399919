import React from 'react'
// import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Clipboard from 'react-clipboard.js'
import ClipboardJS from 'react-clipboard.js'
import shareHover from '../media/share-hover.gif'

type Props = {
  onClick: (event: ClipboardJS.Event) => void
}

function ShareIcon() {
  return <img className="shareIcon" src={shareHover} alt="Share"></img>
}

function ShareButton(props: Props) {
  const { onClick } = props
  return (
    <Clipboard
      component="div"
      data-clipboard-text={window.location}
      onSuccess={onClick}
    >
      <ShareIcon></ShareIcon>
    </Clipboard>
  )
}

export default ShareButton
