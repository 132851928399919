// taken from bootstrap's .fade transision css declaration
const FADE_TRANSITION_DURATION_MS = 150;

let messageBoardEl = null;

// main interval during message showing
let messageDurationTimeout = null;
// interval while message is being hidden
let messageFadeOutTimeout = null;

let nudgeMessage = null;
let nudgeDurationMs = null;
let nudgeEveryMs = null;
let nudgeInterval = null;

export function initMessageBoard(opts) {
  messageBoardEl = opts.el;

  nudgeMessage = opts.nudgeMessage;
  nudgeDurationMs = opts.nudgeDurationMs;
  nudgeEveryMs = opts.nudgeEveryMs;
}

// on react destroy -- don't touch timeouts,
// just clear the element reference.
export function clearMessageBoardEl() {
  messageBoardEl = null;
}

export function initNudge() {
  // nudge is sometimes called while a message is being shown,
  // and takes over the currently shown message... avoid that
  // by not starting nudge intervals while a message is shown.
  if(messageDurationTimeout !== null) {
    return;
  }

  if(nudgeInterval !== null) {
    clearInterval(nudgeInterval);
  }

  nudgeInterval = setInterval(() => {
    hideCurrentMessage().then(() => {
      showMessage(nudgeMessage, nudgeDurationMs)
    });
  }, nudgeEveryMs);
}

export function setMessage(message, duration) {
  // clear nudge
  if(nudgeInterval !== null) {
    clearInterval(nudgeInterval);
  }

  hideCurrentMessage().then(() => {
    showMessage(message, duration);
  });
}

function showMessage(message, duration) {
  function fadeInMessage() {
    messageBoardEl.innerHTML = message;
    messageBoardEl.classList.remove('fade');    
  }

  if(messageBoardEl.classList.contains('fade')) {
    // fade in message
    fadeInMessage();
  } else {
    // if message board is not faded out, add fade, and then remove it to fade in
    messageBoardEl.classList.add('fade');

    setTimeout(() => {
      fadeInMessage();
    }, FADE_TRANSITION_DURATION_MS);
  }

  messageDurationTimeout = setTimeout(() => {
    // re-use hiding function!
    hideCurrentMessage().then(() => {
      // restart nudge
      initNudge();
    });

    // we don't have to clear the message here -- hideCurrentMessage
    // will take care of that.

  // duration is (worse case) message length + fade in transition...!
  }, duration + FADE_TRANSITION_DURATION_MS);
}

function hideCurrentMessage() {
  return new Promise((resolve, reject) => {
    if(messageDurationTimeout !== null) {
      clearInterval(messageDurationTimeout);
      messageDurationTimeout = null;
    }

    // a 'normal' message will probably not have 'fade'
    // in this case, add it...
    if(messageBoardEl.classList.contains('fade') === false) {
      messageBoardEl.classList.add('fade');
    }

    // ...however the message may already have started to fade. in any case,
    // wait at least <bootstrap fade transition length> before clearing the
    // message

    // ..... and also, we might have already started to fade out
    // the message while we were clicked. in that case, reset
    // timeout of message fading out.
    if(messageFadeOutTimeout !== null) {
      clearTimeout(messageFadeOutTimeout);
    }

    // we can finally clear message after fade out period
    messageFadeOutTimeout = setTimeout(() => {
      messageFadeOutTimeout = null;

      // finally clearing the message
      messageBoardEl.innerHTML = '';

      // we're good to go!
      resolve();

    }, FADE_TRANSITION_DURATION_MS);
  });
}
