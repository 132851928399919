import React from 'react'

import tapToLightAnimation from './../media/animations/hint_anim.gif'
import tapToLightAnimation1 from './../media/animations/hint_anim1.gif'
import tapToLightAnimation2 from './../media/animations/hint_anim2.gif'
import tapToLightAnimation3 from './../media/animations/hint_anim3.gif'

type Props = {
  animationName: string
}

const allTapToLightAnimations = [
  tapToLightAnimation,
  tapToLightAnimation1,
  tapToLightAnimation2,
  tapToLightAnimation3
]

const randomTapToLightAnimation = allTapToLightAnimations[
  Math.floor(Math.random() * allTapToLightAnimations.length)
]

const animationNameToGif: { [key: string]: string } = {
  tapToLight: randomTapToLightAnimation,
}

function Animation(props: Props) {
  const { animationName } = props
  const imgSrc = animationNameToGif[animationName]

  return (
    <img
      src={imgSrc}
      alt="animation"
      className={`animation animation-${animationName}`}
      style={{
        zIndex: 1000
      }}
    />
  )
}

export default Animation
