import React, { useState, MouseEvent, useRef, useEffect } from 'react'
import { Dictionary } from 'lodash'
import countapi from 'countapi-js'

import ShareButton from './ShareButton'
import Dreidel from './Dreidel'

import Landing from './Landing'
import Candle from './Candle'

import { SCHEDULE } from './Schedule'
import { QUOTES } from './Quotes'

import { initVolumeMeter } from '../FlameScripts/volume'
import { initMessageBoard, clearMessageBoardEl, initNudge, setMessage } from './UnreactfulUntypedMessage'

// Global var used to talk to webgl shader
window.Hanukkah = window.Hanukkah || {}
// Crucial to initialize at least once for all flames
window.Hanukkah.iVolume = 0

enum ExperienceStatus {
  LANDING = 'landing',
  ALL_CANDLES = 'allCandles',
}

function Experience() {
  const [status, setStatus] = useState<ExperienceStatus>(
    ExperienceStatus.LANDING
  )

  const [index, setIndex] = useState<number>(0)

  const messageEl = useRef<HTMLDivElement>(null)

  const [litState, setLitState] = useState<Dictionary<boolean>>({
    1: litSchedule(1),
    2: litSchedule(2),
    3: litSchedule(3),
    4: litSchedule(4),
    5: litSchedule(5),
    6: litSchedule(6),
    7: litSchedule(7),
    8: litSchedule(8),
  })

  const [lightableState, setLightableState] = useState<Dictionary<boolean>>({
    1: lightableSchedule(1),
    2: lightableSchedule(2),
    3: lightableSchedule(3),
    4: lightableSchedule(4),
    5: lightableSchedule(5),
    6: lightableSchedule(6),
    7: lightableSchedule(7),
    8: lightableSchedule(8),
  })

  function notify(message: string, delay: number) {
    setMessage(message, delay)
  }

  function handleStart(event: MouseEvent) {
    // google tag manager event handling
    // THIS IS CRITICAL AND SHOULD NOT BE REMOVED
    window.dataLayer.push({
      event: 'startButtonClick',
    })

    window.gtag('event', 'startButtonClick');

    // /THIS IS CRITICAL AND SHOULD NOT BE REMOVED

    setStatus(ExperienceStatus.ALL_CANDLES)

    countapi.hit('8flames.com', '40f8610c-8c1c-445e-bbf0-bb0428083ca7').then((counterResult: any) => {
      notify(
        `<p class="h4">Light the candle. ${counterResult.value} candles have already been lit.</p>`,
        60000
      )
    })

    // - Don't attempt to start audio context before user interaction
    // - Will update window.Hanukkah.iVolume as it gets this from the browser's audio stream
    initVolumeMeter()

    initNudge()
  }

  function handleDreidelClick(event: MouseEvent) {
    window.dataLayer.push({
      event: 'dreidelClick',
    })

    window.gtag('event', 'dreidelClick');


    const newIndex = (i: number) => ((i + 1) % QUOTES.length)
    const newQuote = QUOTES[newIndex(index)]
    const delay = newQuote.length * 60

    notify(newQuote, delay)
    setIndex(newIndex(index))
  }

  function handleLight(event: MouseEvent) {
    window.dataLayer.push({
      event: 'lightCandle',
    })

    window.gtag('event', 'lightCandle');
    

    notify('<p class="h4">Happy Hanukkah! Enjoy the light</p>', 4000)

    // this will force the spin message below to appear
    // "out of time", not respecting any other message that might
    // already be shown (if user clicked dreidel), but that's an ok
    // price to pay.
    setTimeout(() => {
      // forced nudge message after happy hanukkah
      notify('<p class="h4">Spin me, you might learn something.</p>', 5000)
    }, 4000)
  }

  function handleShareClick(event: any) {
    notify('<p class="h4">Copied to clipboard</p>', 5000)
  }

  // Should a candle be shown as lit
  function litSchedule(i: number) {
    const today = new Date()

    return SCHEDULE[i + 1] <= today
  }

  // Can a candle be lit by user
  function lightableSchedule(i: number) {
    const today = new Date()

    return SCHEDULE[i] <= today
  }

  useEffect(() => {
    initMessageBoard({
      el: messageEl.current,
      nudgeMessage: '<p class="h4">Spin me, you might learn something.</p>',
      nudgeDurationMs: 4500,
      nudgeEveryMs: 5000
    })

    return () => {
      // kill DOM reference, but don't touch the
      // currently set timeouts
      clearMessageBoardEl()
    }
  })

  return (
    <>
      {status === ExperienceStatus.LANDING && (
        <div id="landing" className="h-100">
          <Landing onStart={handleStart}></Landing>
        </div>
      )}

      {status === ExperienceStatus.ALL_CANDLES && (
        <div id="experience" className="d-flex flex-column h-100">
          <div className="d-flex justify-content-end p-3">
            <a href="#">
              <ShareButton onClick={handleShareClick}></ShareButton>
            </a>
          </div>

          <div className="container flex-fill d-flex flex-column">
            <div className="row">
              <div className="col text-center">
                <Dreidel onClick={handleDreidelClick} />

                <div ref={messageEl}/>
              </div>
            </div>

            <div className="row justify-content-center flex-fill align-items-end">
              <Candle
                lit={litState[1]}
                lightable={lightableState[1]}
                onLight={handleLight}
              ></Candle>
              <Candle
                lit={litState[2]}
                lightable={lightableState[2]}
                onLight={handleLight}
              ></Candle>
              <Candle
                lit={litState[3]}
                lightable={lightableState[3]}
                onLight={handleLight}
              ></Candle>
              <Candle
                lit={litState[4]}
                lightable={lightableState[4]}
                onLight={handleLight}
              ></Candle>
              <Candle
                lit={litState[5]}
                lightable={lightableState[5]}
                onLight={handleLight}
              ></Candle>
              <Candle
                lit={litState[6]}
                lightable={lightableState[6]}
                onLight={handleLight}
              ></Candle>
              <Candle
                lit={litState[7]}
                lightable={lightableState[7]}
                onLight={handleLight}
              ></Candle>
              <Candle
                lit={litState[8]}
                lightable={lightableState[8]}
                onLight={handleLight}
              ></Candle>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Experience
