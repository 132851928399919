import { Dictionary } from 'lodash'

export const SCHEDULE: Dictionary<Date> = {
  1: new Date('December 10, 2020 16:00:00 -0500'),
  2: new Date('December 11, 2020 16:00:00 -0500'),
  3: new Date('December 12, 2020 16:00:00 -0500'),
  4: new Date('December 13, 2020 16:00:00 -0500'),
  5: new Date('December 14, 2020 16:00:00 -0500'),
  6: new Date('December 15, 2020 16:00:00 -0500'),
  7: new Date('December 16, 2020 16:00:00 -0500'),
  8: new Date('December 17, 2020 16:00:00 -0500'),
  9: new Date('January 18, 2021 16:00:00 -0500'),
}
