import React, { useEffect, useState, MouseEvent } from 'react'
import { MemoizedFlame } from './Flame'
import Animation from './Animation'

import candleOn from './../media/candle-on.png'
import candleOff from './../media/candle-off.png'
import candleDash from './../media/candle-dash.png'
import halo from './../media/wick_halo_thinner.png'

type Props = {
  lit: boolean
  lightable: boolean
  onLight?: (event: MouseEvent) => void
}

function Candle(props: Props) {
  const { lit, lightable, onLight } = props

  const [showFlame, setShowFlame] = useState(lit)
  const [showAnimation, setShowAnimation] = useState(lightable && !lit)

  function handleClick(event: MouseEvent) {
    if (lightable) {
      setShowFlame(true)
      setShowAnimation(false)
      onLight && onLight(event)
    }
  }

  useEffect(() => {
    if (lit) {
      setShowFlame(true)

      if (lightable) {
        setShowAnimation(false)
      }
    } else {
      setShowFlame(false)

      if (lightable) {
        setShowAnimation(true)
      }
    }
  }, [lit, lightable])

  return (
    <div className="col text-center" onClick={handleClick}>
      <div
        className={`candleContainer ${lightable ? 'lightable' : ''} ${
          lit ? 'lit' : ''
        }`}
      >
        {showAnimation && <Animation animationName="tapToLight" />}

        <img
          src={lightable ? (showFlame ? candleOn : candleOff) : candleDash}
          className="candle"
          draggable={false}
          alt=""
          style={{
            zIndex: 1050
          }}
        />

        <div className="blend">
          {lightable && <MemoizedFlame visible={showFlame} />}

          <img
            src={halo}
            className={`halo ${showFlame ? 'visible' : 'invisible'}`}
            draggable={false}
            alt=""
            style={{
              zIndex: 1100
            }}
          ></img>
        </div>
      </div>
    </div>
  )
}

export default Candle
