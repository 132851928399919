import shuffle from 'lodash/shuffle'

export const QUOTES = shuffle([
  '<p class="h4">Undoubtedly the <a target="_blank" href="https://www.youtube.com/watch?v=KX5Z-HpHH9g&list=LLXqbY_khGdKApe7_R9uHgMg&index=65">greatest hanukkah song ever</a></p>',
  '<p class="h4">Because <a target="_blank" href="https://www.youtube.com/watch?v=v37kl3nuL0Y">The Hebrew Hammer</a> is a real movie</p>',
  '<p class="h4">The celebration of light and/or not yet having been scapegoated for covid-19</p>',
  '<p class="h4"><a target="_blank" href="https://baseballhall.org/discover/sandy-koufax-sits-out-game-one">Nobody</a> pitches on hanukkah</p>',
  '<p class="h4"><a target="_blank" href="https://www.youtube.com/watch?v=4AhSL1wS_gE&feature=emb_logo">This</a> is how you pronounce it</p>',
  '<p class="h4">Sure, it’s no Eid, but, then again, what is Eid? (Really. <a target="_blank" href="https://en.wikipedia.org/wiki/Eid_al-Fitr">What’s Eid</a>?)</p>',
  '<p class="h4">If you don’t have any gelt, you can always play dreidel with alcohol. Or, if you only have alcohol, that’s ok too</p>',
  '<p class="h4">A time for pretending you don’t wish you celebrated christmas</p>',
  '<p class="h4">I mean, you can’t drive out the greeks and then act all surprised that you don’t have enough olive oil</p>',
  '<p class="h4">No one cares how you spell it (<a target="_blank" href="https://www.druide.com/en/reports/why-are-there-so-many-ways-spell-hanukkah-or-it-chanukah">but if you’re interested…</a>)</p>',
  '<p class="h4">Hanukkah: takes you back to when you were just a little yid</p>',
  '<p class="h4">“Hanukkah. They tried to kill us. We won. Let’s eat!”*  <br/> *Also good for Passover, Purim…</p>',
  '<p class="h4">A commemoration of victory against Jewish assimilation by way of surrender to American commercialism</p>',
  '<p class="h4">A time for awkwardly outing yourself as Jewish at office christmas parties</p>',
  '<p class="h4">The Maccabees vs. Greeks. Jewish holidays - what’s with all the killing?</p>',
  '<p class="h4">A holiday about the quiet, childish resentment felt when entirely well-meaning Christian colleagues and acquaintances wish you a Merry Christmas</p>',
  '<p class="h4">It’s about a bunch of guerilla fighters seeking to overthrow an internationalist colonial power in the name of reasserting religious orthodoxy</p>',
  '<p class="h4">Hey bright eyes, did you know that <em>svivon</em> — the hebrew word for dreidel — means to <a target="_blank" href="https://www.youtube.com/watch?v=lcOxhH8N3Bo">turn around</a>?</p>',
  '<p class="h4">Did you know that Israeli dreidels are different than in all other places. You know why?</p>',
  '<p class="h4">Playing dreidel is fun for a very short period of time</p>',
  '<p class="h4">Yiddish names for dreidel include <em>Something-Thrown, Put-In-All</em>, and <em>No-Way-That-Fell-Off-The-Table-It-Doesn’t-Count</em></p>',
  '<p class="h4">Dreidel’s origins are to be found in...a medieval European game??</p>',
  '<p class="h4">Egyptian Jews apparently played with animal bone dreidels</p>',
  '<p class="h4">The rabbis talk about a connection between Purim groggers and dreidels. I don’t see it</p>',
  '<p class="h4">According the kabbalists, the dreidel letters stand for <em>body, soul, understanding, and universal connection</em></p>',
  '<p class="h4">Ancient Jews played dreidel to hide their Torah study, which the Greeks had outlawed</p>',
  '<p class="h4">Christain Bale, I know! Like, what kind of a <a target="_blank" href="https://getyarn.io/yarn-clip/7002e48b-5375-404c-b0d6-b16857fd427e">psycho</a> doesn’t even know the difference between a dreidel and a menorah?</p>',
  '<p class="h4">In the movie X-Men: First Class, Magneto is shown as a young boy lighting a menorah</p>',
  '<p class="h4">The Jewish rebels came to be known as the Maccabees — meaning “hammer”</p>',
  '<p class="h4">Fighting a guerrilla insurgence against the much larger Greek army, the Maccabees utilized such asymmetric warfare tactics as <a target="_blank" href="https://www.youtube.com/watch?v=Mw0riLqQBj0">poking, slapping, pinching, and running around in circles</a></p>',
  '<p class="h4">Less an uprising against foreign oppression, some argue the Maccabean revolt was more a civil war between orthodox and reformist Jewish camps. But now who could believe that…</p>',
  '<p class="h4">Some Hellenistic Jews chose willingly to adopt the Greek culture, whereas others had reason and aesthetics thrust upon them</p>',
  '<p class="h4">As part of their forced assimilation campaign, the Greeks prohibited circumcision - and—well, that’s just the tip</p>',
  '<p class="h4">Hanukkah candles aren’t meant to be used for any practical purpose. It’s just about the light</p>',
  '<p class="h4">Daveed Diggs is a superhero <a target="_blank" href="https://www.youtube.com/watch?v=gbxyZAduGvY">Puppy for Channukah</a></p>',
  '<p class="h4"><a target="_blank" href="https://www.youtube.com/watch?v=c5uMRhj_N6Y">The Grav Menorah</a> is a bong that…uhh……wha??</p>',
  '<p class="h4">In 1989 <a target="_blank" href="https://en.wikipedia.org/wiki/County_of_Allegheny_v._American_Civil_Liberties_Union">the Supreme Court</a> ruled that an 18-foot Menorah display did not violate the 1st Amendment</p>',
  '<p class="h4">“Hanukkah” literally means <em>dedication</em>! Like when cleansing or re-purifying a Temple. What do you want to dedicate yourself to?</p>',
  '<p class="h4">Hanukkah? Chanukah?? Chanukkah?!? Whatever</p>',
  '<p class="h4">The miracle lasted seven days, but we celebrate for eight. That’s a good question</p>',
  '<p class="h4">Hanukkah dates on the US calendar change annually, every year. It\'s cuz the Jewish year is a combo of lunar and solar. The gregorian calendar is just solar</p>',
  '<p class="h4">Jimmy Carter was the <em>first</em> US president to formally recognize Hanukkah</p>',
  '<p class="h4">Yes, yes, <a target="_blank" href="https://www.curtlandry.com/what-is-the-difference-between-the-hanukkiah-and-the-menorah/#.X9E6ry2ZPOQ">not all menorahs are hanukkiahs</a>.</p>',
  '<p class="h4">In Israel the custom is for Jews to light the Menorah outside. But doing it on your phone with us is cool too</p>',
  '<p class="h4">There is a custom to place Menorahs facing the “public” space. You see a lot of them in the windows for that reason</p>',
  '<p class="h4">I’ll grant you that historically it wasn’t about gifts, and eight nights of presents is certainly excessive, but who\'s complaining?</p>',
  '<p class="h4">Long before it was latkes, <a target="_blank" href="https://www.post-gazette.com/life/food/2017/12/06/Hanukkah-foods/stories/201711280139">fried cheese</a> was the food of Hanukkah. As long as you\'re eating</p>',
  '<p class="h4">To blend in with Greeks at the baths, some ancient Jews even went through reverse circumcision procedures. Really</p>',
  '<p class="h4">I’m sorry — did you say lost <em><a target="_blank" href="https://www.timeout.com/newyork/music/best-hanukkah-songs">vibes</a></em> of Israel?</p>',
  '<p class="h4">A Smörgåsbord (which is Swedish) of <a target="_blank" href="https://www.jpost.com/omg/eight-viral-videos-for-eight-nights-of-hanukkah-fun-573348">Hanukkah Viral Vids</a></p>',
  '<p class="h4">Sufjan Stevens? Flaming Lips!? Yo La Tengo?!? A <a target="_blank" href="https://www.udiscovermusic.com/stories/best-hanukkah-songs/">playlist</a> to make you proud</p>',
  '<p class="h4">All they’ll want for Christmas are <a target="_blank" href="https://www.kveller.com/article/top-ten-hanukkah-songs/">more Hanukkah songs</a></p>',
  '<p class="h4">Kiddie cartoons (and some tunes) for the <a target="_blank" href="https://pjlibrary.org/beyond-books/pjblog/december-2016/hanukkah-videos-and-movies-to-watch-together">bubbes and bébés</a></p>',
  '<p class="h4">From Elmo to Cholo, Some <a target="_blank" href="https://www.ynetnews.com/articles/0,7340,L-4461210,00.html">Meshuggenah Video</a></p>',
  '<p class="h4">Too $hort <a target="_blank" href="https://www.youtube.com/watch?v=-AV0Nio5lDk">Hanukkah hip hop</a>. Turn the candlelight low…</p>',
  '<p class="h4"><em>That’s funny, you don’t *look* Jewish:</em> 10 <a target="_blank" href="https://www.delish.com/holiday-recipes/hanukkah/g1334/creative-hanukkah-latke-recipes/">new recipes</a> you’ll latke</p>',
  '<p class="h4"><em>Whaddya mean you mean don’t even know <a target="_blank" href="https://en.wikipedia.org/wiki/Latke">what a latke is</a>?!?</em></p>',
  '<p class="h4">The Guide for the Perplexed <a target="_blank" href="https://www.theatlantic.com/entertainment/archive/2015/12/the-great-latke-lie/420018/">about latkes</a></p>',
  '<p class="h4">Just the Facts, Ma’am: a <a target="_blank" href="https://www.history.com/topics/holidays/hanukkah#sectio">History of Hanukkah</a></p>',
  '<p class="h4">WWJD?!? <a target="_blank" href="http://www.josephus.org/hanukkah.htm#nt">Celebrate Hanukkah!</a></p>',
  '<p class="h4">Ya <a target="_blank" href="https://www.haaretz.com/jewish/.premium-the-more-likely-explanation-of-the-menorah-1.5346542">Know-a Menorah?</a></p>',
  '<p class="h4">Let us help you <a target="_blank" href="https://www.rd.com/list/hanukah-gifts/">with the heavy-gifting</a></p>',
  '<p class="h4">All the <s>Single</s> <a target="_blank" href="https://jwa.org/topics/hanukkah">Jewish Ladies</a></p>',
  '<p class="h4">Just a Couple of <a target="_blank" href="https://www.myjewishlearning.com/article/women-as-role-models/">Bad Hanukkah B*tches</a></p>',
  '<p class="h4"><em>And thou shalt love <a target="_blank" href="https://www.pbs.org/food/features/history-of-latkes/#:~:text=Of%20course%20we%20associate%20potato,1286%2D1328)">the latke</a></em></p>',
  '<p class="h4">How in <a target="_blank" href="https://www.heyalma.com/you-need-to-know-about-north-africas-feminist-hanukkah-tradition/">North Africa</a> the female Jews do</p>',
  '<p class="h4">These <a target="_blank" href="https://reformjudaism.org/dedicate-each-hanukkah-candle-strong-biblical-woman">biblical babes</a> can light their own candles, thank you very much</p>',
  '<p class="h4">A HuffPo <a target="_blank" href="https://www.huffingtonpost.ca/entry/real-history-of-hanukkah_n_585ea38ae4b0d9a594587fd3?ri18n=true">Hanukkah Historiography</a></p>',
])
